import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, Box } from "@material-ui/core";
import AboutPic from "../../assets/images/aboutPic.jpg";

const useStyles = makeStyles((theme) => ({
  media: {
    height: "300px",
    backgroundPosition: "inherit",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000085",
  },
  paragraph: {
    fontSize: "20px",
    lineHeight: "2rem",
  },
  gridParent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    position: "relative",
    border: "none",
    boxShadow: "none",
  },
  gridContainer: {
    paddingTop: "3rem",
    paddingBottom: "2rem",
    paddingLeft: "1rem",
    paddingRight: "5rem",
  },
  whatwedo: {
    display: "flex",
    height: "38rem",
    width: "43rem",
  },
  text: {
    color: "white",
    paddingTop: "6em",
    fontFamily: "sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  topLine: {
    fontSize: "30px",
    height: ".5rem",
    paddingTop: "50px",
  },
  header: {
    color: "blue",
    paddingBottom: "30px",
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <body style={{ backgroundColor: "#d7e0e3" }}>
        <Grid
          container
          spacing={0}
          className={classes.gridContainer}
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={AboutPic} className={classes.whatwedo} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <div>
              <h1 className={classes.header}>Meet Me - Daniel</h1>
              <p className={classes.paragraph}>
                I've been an avid Mountain and road biker for many years! I fix
                and upgrade people's bikes for fun and as a hobbie!
              </p>
              <p className={classes.paragraph}>
                One of the most beautiful things about the sport of mountain
                biking is the dedication of clusters of mountain bikers spread
                around the planet who are constantly building newer and better
                mountain bike trails for travel-thirsty riders to explore and
                enjoy. Over the years we’ve drawn on our massive mountain bike
                trail database, the expertise of our widespread editorial team,
                survey data from our millions of readers (you), and rumors that
                our staff has collected from all corners of the nation, in order
                to assemble lists of the Top 10 Mountain Bike Destinations in
                North America and the Top 10 Mountain Bike Cities in North
                America. In the intervening three years since we published those
                first top 10 lists, the landscape of mountain biking in the
                United States has changed. Many top-level destinations have
                continued to build new and better trails, expanding their trail
                networks even more. Some destinations that didn’t make the cut
                before have built out astronomical amounts of new singletrack,
                subsequently building their national profile. And still other
                destinations have built very few or absolutely no new trails,
                and have fallen by the wayside as a result.
              </p>
            </div>
          </Grid>
        </Grid>
      </body>
    </React.Fragment>
  );
};

export default About;
