import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "4.7rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "3.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3.2rem",
    },
  },
  logo: {
    height: "6rem",
    paddingLeft: "3rem",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    [theme.breakpoints.down("md")]: {
      height: "5rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4rem",
    },
  },
  customToolbar: {
    minHeight: "6rem",
  },
  tabContainer: {
    marginLeft: "35%",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
    },
  },
  tab: {
    fontWeight: 500,
    fontSize: ".85rem",
    //need width and min width both implement-high-fidelity-designs-with-material-ui-and-reactjs/learn/lecture/16284888#questions/10616142
    width: "10rem",
    minWidth: ".5rem",
  },
  button: {
    marginLeft: "500px",
    borderRadius: "50px",
    fontFamily: "Pacifico",
    textTransform: "none",
    height: "50px",
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "50px",
    width: "50px",
    color: "#073c54",
  },
  drawer: {
    backgroundColor: theme.palette.common.blue,
    width: "50%",
  },
  drawerItem: {
    color: "white",
  },
  drawerContactMe: {
    backgroundColor: "#5e837f",
    fontFamily: "Pacifico",
  },
  drawerItemSelected: {
    opacity: 1,
    color: "grey",
  },
}));

const Header = (props) => {
  const theme = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (window.location.pathname === "/" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/services" && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === "/about" && value !== 2) {
      setValue(2);
    }
  }, [value]);

  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabContainer}
        centered
        classes={{ indicator: classes.styledIndicator }}
      >
        <Tab className={classes.tab} label="Home" component={Link} to="/"></Tab>
        <Tab
          className={classes.tab}
          label="Services"
          component={Link}
          to="/services"
        ></Tab>
        <Tab
          className={classes.tab}
          label="About Me"
          component={Link}
          to="/about"
        ></Tab>
      </Tabs>
      <Button
        component={Link}
        to="/services"
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => {
          setOpenDrawer(false);
          props.executeScroll();
          setValue(1);
        }}
      >
        Contact Me
      </Button>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <List disablePadding>
          <ListItem
            selected={value === 0}
            onClick={() => {
              setOpenDrawer(false);
              setValue(0);
            }}
            divider
            button
            component={Link}
            to="/"
          >
            <ListItemText
              className={
                value === 0
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              Home
            </ListItemText>
          </ListItem>
          <ListItem
            selected={value === 1}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(1);
            }}
            divider
            button
            component={Link}
            to="/services"
          >
            <ListItemText
              className={
                value === 1
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              Services
            </ListItemText>
          </ListItem>
          <ListItem
            selected={value === 2}
            onClick={() => {
              setOpenDrawer(false);
              setValue(2);
            }}
            divider
            button
            component={Link}
            to="/about"
          >
            <ListItemText
              className={
                value === 2
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              About
            </ListItemText>
          </ListItem>
          <ListItem
            className={classes.drawerContactMe}
            selected={value === 3}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(3);
            }}
            divider
            button
            component={Link}
            to="/services"
          >
            <ListItemText
              className={
                value === 3
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              Contact Me
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={classes.drawerIcon}></MenuIcon>
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <Toolbar className={classes.customToolbar} disableGutters>
            <Button
              disableRipple
              style={{ backgroundColor: "transparent" }}
              component={Link}
              to="/"
              onClick={handleChange}
            >
              <img
                alt="Daniels Bike repair Logo"
                className={classes.logo}
                src={logo}
              />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* This is used to have that gap in between the header and content below #20 styling inline 8:21 */}
      <div className={classes.toolbarMargin}></div>
    </React.Fragment>
  );
};

export default Header;
