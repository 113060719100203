import { ThemeProvider } from "@material-ui/styles";
import React, { useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./ui/Header";
import theme from "./ui/Theme";
// import theme from "./ui/Theme";
import Services from "./Services/Services";
import Home from "./Home/Home";
import About from "./About/About";
// import Contact from "./Contact/Contact";
import Footer from "../components/ui/Footer";
// import { createTheme } from "@material-ui/core/styles";

function App() {
  const ref = useRef();
  //const  = () => ref.current.scrollIntoView();
  //const [value, setValue] = useState(0);
  const executeScroll = () => {
    setTimeout(function () {
      if (ref) {
        ref.current.scrollIntoView();
      }
    }, 300);
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header executeScroll={executeScroll}></Header>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            exact
            path="/services"
            element={<Services ref={ref} />}
          ></Route>
          <Route exact path="/about" element={<About />}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
