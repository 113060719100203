import { createTheme } from "@material-ui/core/styles";

//const nebBlue = "#5e837f";
const nebBlue = "#5e837f";
//#1d1c1c
const nebGrey = "#073c54";
const nebGreen = "#07b8b8";
const nebDarkBlue = "#1A3352";
//const underCarriage = "#01aee3";
//#0e2c58
//#1d1c1c
const theme = createTheme({
  palette: {
    common: {
      nebBlue: `${nebBlue}`,
      nebGrey: `${nebGrey}`,
      blue: `${nebGrey}`,
      nebGreen: `${nebGreen}`,
      nebDarkBlue: `${nebDarkBlue}`,
    },
    primary: {
      main: `${nebBlue}`,
    },
    secondary: {
      main: `${nebGrey}`,
    },
  },
  typography: {
    h3: {
      fontWeight: 700,
    },
    tab: {
      color: "blue",
    },
  },
});

export default theme;
