import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Card, CardMedia, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ServicesBanner from "../../assets/images/servicesBanner.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      textAlign: "center",
      paddingRight: "150px",
    },
  },
  checkmarkStyle: {
    color: "blue",
  },
  cost: {
    color: "red",
    fontWeight: "700",
  },
  otherItems: {
    align: "center",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000085",
  },
  text: {
    color: "white",
    paddingTop: "6em",
    fontFamily: "sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  topLine: {
    fontSize: "30px",
    height: ".5rem",
    paddingTop: "50px",
  },
  media: {
    height: "650px",
    backgroundPosition: "inherit",
  },
  card: {
    position: "relative",
    border: "none",
    boxShadow: "none",
  },
  contact: {
    textAlign: "center",
    color: "white",
  },
}));

const Services = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardMedia image={ServicesBanner} className={classes.media} />
        <div className={classes.overlay}>
          <div className={classes.text}>
            <div className={classes.topLine}>
              <h2>Services</h2>
            </div>
          </div>
        </div>
      </Card>

      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" className={classes.title}>
            Basic Tune Up
            <Typography variant="h5" className={classes.cost}>
              $99.99*
            </Typography>
          </Typography>
          <div className={classes.demo}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Lube Necessary Moving Parts" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjust Brakes & Shifters" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" True Wheels" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Wipe Down Bike" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjustments Tested" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Labor For Tire & Tube Replacement" />
              </ListItem>
            </List>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" className={classes.title}>
            Silver Tune Up
            <Typography variant="h5" className={classes.cost}>
              $149.99*
            </Typography>
          </Typography>
          <div className={classes.demo}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Lube Necessary Moving Parts" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjust Brakes & Shifters" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" True Wheels" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Wipe Down Bike" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjustments Tested" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Labor For Tire & Tube Replacement" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" className={classes.title}>
            Gold Tune Up
            <Typography variant="h5" className={classes.cost}>
              $249.99*
            </Typography>
          </Typography>
          <div className={classes.demo}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon style={{ color: "blue" }} />
                </ListItemIcon>
                <ListItemText primary="Lube Necessary Moving Parts" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjust Brakes & Shifters" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" True Wheels" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Wipe Down Bike" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjustments Tested" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Labor For Tire & Tube Replacement" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" className={classes.title}>
            Platinum Tune Up
            <Typography variant="h5" className={classes.cost}>
              $349.99*
            </Typography>
          </Typography>
          <div className={classes.demo}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon style={{ color: "blue" }} />
                </ListItemIcon>
                <ListItemText primary="Lube Necessary Moving Parts" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjust Brakes & Shifters" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" True Wheels" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Wipe Down Bike" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary=" Adjustments Tested" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.checkmarkStyle} />
                </ListItemIcon>
                <ListItemText primary="Labor For Tire & Tube Replacement" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          item
          xs={12}
          sm={12}
          md={12}
        >
          <Typography
            variant="h6"
            className={classes.title}
            style={{ paddingRight: "20px" }}
          >
            Other Items
          </Typography>
          <div className={classes.otherItems}>
            <List>
              <ListItem>
                <ListItemText primary="Lube Necessary Moving Parts" />
              </ListItem>
              <ListItem>
                <ListItemText primary=" Adjust Brakes & Shifters" />
              </ListItem>
              <ListItem>
                <ListItemText primary=" True Wheels" />
              </ListItem>
              <ListItem>
                <ListItemText primary=" Wipe Down Bike" />
              </ListItem>
              <ListItem>
                <ListItemText primary=" Adjustments Tested" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Labor For Tire & Tube Replacement" />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
      <body style={{ height: "300px", backgroundColor: "grey" }}>
        <h1 className={classes.contact} ref={ref}>
          Get In touch
        </h1>
      </body>
    </React.Fragment>
  );
});

export default Services;
