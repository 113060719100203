import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Card, CardMedia, Grid, Button } from "@material-ui/core";
//import HomeBanner from "../../assets/images/homeBanner.jpg";
import HomeBanner from "../../assets/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100px",
  },
  card: {
    position: "relative",
    border: "none",
    boxShadow: "none",
  },
  media: {
    height: "800px",
    backgroundSize: "contain",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#2d383185", //#00000085 not bad color
  },
  topLine: {
    fontSize: "1.7rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
    height: ".5rem",
    paddingTop: "50px",
  },
  bottomLine: {
    fontSize: "2.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  text: {
    color: "white",
    paddingTop: "6em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <div style={{ height: "100px" }}></div>
      <div style={{ height: "100%" }}>
        <h1 style={{ textAlign: "center" }}>
          Get Back On The Road or Hit Those Trails!
        </h1>
        <Card className={classes.card}>
          <CardMedia image={HomeBanner} className={classes.media} />
        </Card>
        <h1 style={{ textAlign: "center" }}>
          Serving The McKinney and Dallas area since 2001
        </h1>
        <div style={{ height: "100px" }}></div>
      </div>
    </React.Fragment>
  );
};

export default Home;
