import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import NebucorpLogo from "../../assets/bottomLogo.svg";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";
import { useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.common.blue,
    // width: "100%",
    height: "4.5rem",
    backgroundColor: theme.palette.common.nebGrey,
    position: "relative",
  },
  tabContainer: {
    marginLeft: "Auto",
  },
  styledIndicator: {
    backgroundColor: "#01aee3",
  },
  button: {
    paddingTop: "0",
    marginLeft: "Auto",
    marginTop: "Auto",
  },
  container: {
    //display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tab: {
    color: "#fff", // changes font color keeping white for now
  },
  adornment: {
    width: "10rem",
    // verticalAlign: "bottom",
    paddingTop: ".9rem",
    paddingBottom: "1rem",
    // paddingLeft: "6rem",
    paddingRight: "1rem",
  },
  buttonpad: {
    paddingLeft: "4rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  copyright: {
    color: "white",
    fontSize: "80%",
    fontWeight: "400",
    // paddingTop: "3rem",
    fontFamily: "'Open Sans', sans-serif",
  },
  logo: {
    width: "20rem",
  },
}));

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (e, value) => {
    setValue(value);
  };

  useEffect(() => {
    if (window.location.pathname === "/" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/services" && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === "/about" && value !== 0) {
      setValue(2);
    }
  }, [value]);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/* <Button className={classes.button}>
      <img src={NebucorpLogo} alt="Nothing" className={classes.adornment} />
    </Button> */}

        <Toolbar className={classes.customizeToolbar}>
          <div className={classes.buttonpad}>
            <Button
              component={Link}
              className={classes.adornment}
              disableRipple
              to="/"
            >
              <img
                src={NebucorpLogo}
                className={classes.logo}
                onClick={() => {
                  setValue(0);
                }}
                alt="Company logo"
              />
            </Button>
          </div>
          {matches ? (
            matches
          ) : (
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              className={classes.tabContainer}
              // classes={{ indicator: classes.styledIndicator }}
              indicatorColor="secondary"
            >
              <Tab
                className={classes.tab}
                component={Link}
                to="/"
                label="Home"
              />
              <Tab
                className={classes.tab}
                component={Link}
                label="Services"
                to="/services"
              />
              <Tab
                className={classes.tab}
                component={Link}
                label="About Me"
                to="about"
              />
            </Tabs>
          )}
        </Toolbar>
      </div>
    </footer>
  );
};

export default Footer;
